<template>
  <v-card class="wallet-widget-wrap widget-wrapper">
    <div class="title-wrap d-flex align-center">
      <ph-wallet :size="30" :color="'#808081'" />
      <block-text
        :text="$t('header.myWallet')"
        class="ml-3"
        type="title"
        color="secondary"
      />
    </div>
    <div class="balance-wrap py-6 d-flex align-top">
      <div class="balance d-flex align-center pr-5">
        <v-img
          class="ttu-coin-img"
          width="48"
          height="48"
          :src="require('@/assets/ttuToken.svg')"
        />
        <div class="account-balance pl-4 d-flex flex-column">
          <block-text
            :text="user.balance.toFixed(2)"
            color="primary"
            type="headline"
            weight="bold"
          />
          <block-text
            :text="$t('wallet.accountBalance')"
            class="pt-1 text"
            color="secondary"
          />
        </div>
      </div>
      <div class="pending-transfer pl-5 d-flex flex-column">
        <block-text
          :text="user.pending.toFixed(2)"
          type="headline"
          color="secondary"
        />
        <block-text
          :text="$t('wallet.pendingTransfer')"
          class="pt-1 text"
          color="secondary"
        />
      </div>
    </div>
    <div class="wallet-address-wrap py-6">
      <block-text
        :text="$t('wallet.myAddress')"
        class="copy text"
        color="secondary"
        weight="bold"
        type="body-1"
      />
      <div class="address d-flex align-center justify-space-between pt-2">
        <block-text
          :text="user.walletId"
          class="wallet-id pr-2"
          color="primary"
          type="body-1"
        />
        <div
          class="copy-wrapper cursor-pointer d-flex align-center"
          @click="copyWalletId(user.walletId)"
        >
          <ph-copy
            :size="16"
            :color="$vuetify.theme.dark ? '#fff' : '#222'"
            weight="bold"
          />
          <block-text
            :text="$t('wallet.copy')"
            class="copy pl-2"
            type="body-1"
            weight="bold"
          />
        </div>
      </div>
    </div>
    <div class="actions-wrap d-flex flex-column pt-7">
      <v-btn
        @click="redeemNow"
        outlined
        rounded
        :elevation="0"
        text
        :color="'jungleGreen'"
        :ripple="false"
        :plain="false"
        :style="{ height: '40px' }"
        class="text-capitalize body-1 flex-grow-1 font-weight-bold"
        >{{ $t("wallet.redeemNow") }}
        <ph-shopping-cart :size="30" class="pl-3"></ph-shopping-cart
      ></v-btn>
      <v-btn
        @click="toggleDialog"
        outlined
        rounded
        :elevation="0"
        text
        :color="'jungleGreen'"
        :ripple="false"
        :plain="false"
        :style="{ height: '40px' }"
        class="text-capitalize body-1 flex-grow-1 font-weight-bold mt-3 mb-2"
        >{{ $t("wallet.sendTTU") }}
        <ph-paper-plane-tilt :size="30" class="pl-3"></ph-paper-plane-tilt
      ></v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  PhWallet,
  PhCopy,
  PhShoppingCart,
  PhPaperPlaneTilt,
} from "phosphor-vue";
export default {
  components: {
    PhWallet,
    PhCopy,
    PhShoppingCart,
    PhPaperPlaneTilt,
  },
  computed: {
    ...mapGetters({
      user: "wallet/user",
    }),
  },
  methods: {
    ...mapActions({
      getECommerceUrl: "wallet/getECommerceUrl",
    }),
    async redeemNow() {
      const shopifyUrl = await this.getECommerceUrl({
        callback: process.env.VUE_APP_REDEEM,
      });

      location.href = shopifyUrl;
    },
    copyWalletId(walletId) {
      if (!navigator.clipboard) {
        this.fallbackCopyWalletId(walletId);
        return;
      }
      this.$store.dispatch("wallet/copyWalletId", walletId);
    },
    fallbackCopyWalletId(walletId) {
      let textArea = document.createElement("textarea");
      textArea.value = walletId;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        let successful = document.execCommand("copy");
        let msg = successful ? "successful" : "unsuccessful";
      } catch (err) {
        console.error("Unable to copy", err);
      }
    },
    toggleDialog() {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        headerIcon: "PhPaperPlaneTilt",
        dialogPath: "components/Dialogs/SendTTUDialog",
        title: this.$t("dialog.sendTTU"),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet-widget-wrap {
  .balance-wrap {
    border-bottom: 1px solid var(--v-border-base);
    .balance {
      border-right: 1px solid var(--v-border-base);
    }
  }
  .wallet-address-wrap {
    border-bottom: 1px solid var(--v-border-base);
    .wallet-id {
      font-size: 12px;
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .wallet-widget-wrap {
    // box-shadow: $box-shadow-dark;
    // border-radius: $radius-md;
    .wallet-id {
      font-size: 12px !important;
    }
  }
}
</style>
